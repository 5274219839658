import React, { useEffect, useState } from "react";
import backendAPI, { getPromptsByGradeAPI } from "../backend-api/BackendAPI";
import { defaultPrompt, defaultWordLimit } from '../constants/constants';
import { IPrompt } from "../interfaces/DataModels";
import { getAuthHeader } from "../utils/AuthUtils";
import { getUserDetails } from "../utils/GeneralUtils";
import GradePromptsTable from "./GradePromptsTable";

const PromptsForGrade = (props: any) => {
  // `grade` will be passed as a prop.
  // `wordLimit` will be passed as a prop.

  const defaultPromptList = [defaultPrompt];
  const [prompts, setPrompts] = useState<Array<IPrompt>>(defaultPromptList);
  const [showAlert, setShowAlert] = useState(null);

  const getPrompts = () => {
    const config = {
      headers: getAuthHeader(),
      params: {
        grade: props.grade,
        word_limit: props.wordLimit
      }
    };
    let prompts = [];
    backendAPI.get(getPromptsByGradeAPI, config)
    .then(response => {
      console.log("Retrieved prompts for grade: " + props.grade + " with word limit: " + props.wordLimit + " successfully.");
      console.log("Response data: "); console.log(response.data);
      prompts = response.data;
      console.log("Prompts being returned: ");
      console.log(prompts);
      setPrompts(prompts);
      // return prompts;
    })
    .catch(e => {
      console.log("Something went wrong."); console.log(e);
    });
    // return prompts;
  }

  useEffect(() => {
    setPrompts(defaultPromptList);
    getPrompts();
  }, [props.grade, props.wordLimit]);

  console.log("Props: "); console.log(props);

  const noPromptsFound = () => {
    return prompts == defaultPromptList || !prompts || prompts.length == 0;
  }

  return (
    <div className="PromptsForGrade">
      {/* {showAlert ? <Alert severity='error'>{alertContent}</Alert> : <></> } */}
      {/* <h3>Topics for Grade {props.grade} (word limit: {props.wordLimit})</h3> */}
        {console.log("Prompts: ")}{console.log(prompts)}

      {
        prompts == defaultPromptList ? "Loading..." : 
          prompts.length == 0 ? "No topics found." : <GradePromptsTable rows={prompts} />
      }
      {console.log("Prompts: ")}{console.log(prompts)}
    </div>
  )
}

export default PromptsForGrade;
