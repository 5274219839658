import { InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export enum AssignmentType {
  All,
  Essay,
  Summary,
};

const AssignmentTypeDropDown = (props: any) => {
  const { name, value, onChange, ...rest } = props;
  const items: any = [AssignmentType.All, AssignmentType.Essay, AssignmentType.Summary];
  const noOptions: boolean = (!items || items.length == 0);
  console.log(`No options in drop down for ${name}? ${noOptions}`);
  return (
    <div className="form-group" style={{ display: 'inline-block', marginLeft: '20px' }}>
      <InputLabel htmlFor={name}>Assignment Type</InputLabel>
      <Select
        value={value}
        name={name}
        id={name}
        onChange={onChange}
        required
        sx={{ minWidth: 120 }}
        disabled={noOptions}
        {...rest}
      >
      { noOptions && <MenuItem value=''><em>None</em></MenuItem> }
      {items
        .map(item => (
          <MenuItem key={item} value={item}>
            {
              AssignmentType[item]
            }
          </MenuItem>
      ))}
      </Select>
    </div>
  );
}

export default AssignmentTypeDropDown;
