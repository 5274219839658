import { Button } from '@mui/material';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IUser } from '../../interfaces/DataModels';
import { blankUser, supportedGrades } from '../../constants/constants';
import { getUserDetails } from '../../utils/GeneralUtils';
import { FormFieldDropDown, FormFieldInput } from '../../utils/FormFields';
import { getAuthHeader } from '../../utils/AuthUtils';
import backendAPI, { createUserAPI } from '../../backend-api/BackendAPI';

interface IUserState extends IUser {
  submitted: boolean;
};

const CreateUser = (props: any) => {
  const defaultUserState = {
    ...blankUser,
    submitted: false,
  }
  const [userState, setUserState] = useState<IUserState>(defaultUserState);
  const userDetails = getUserDetails();

  const setNewUser = () => {
    setUserState(defaultUserState);
  }

  const onChange = (event) => {
    const {name, value} = event.target;
    setUserState({
      ...userState,
      [name]: value,
    });
  }

  const createUser = (event) => {
    event.preventDefault();
    const data = {
      ...userState,
      school_id: userDetails.school_id,
    }
    const config = {
      headers: getAuthHeader()
    };
    console.log("Create user data: "); console.log(userState);
    backendAPI.post(createUserAPI, data, config)
      .then(response => {
        console.log("Success: User created.")
        setUserState({
          ...userState,
          submitted: true
        });
        console.log(response.data);
        alert('New user created successfully.');
      })
      .catch(error => {
        console.log("Failure: User not created.");
        console.log(error.response);
        const errorMessage = error.response.data.detail;
        alert(errorMessage);
      });
  }

  return (
    <div className="submit-form">
      {userState.submitted ? (
        <div>
          <h4>User created successfully.</h4>
          <Button className="btn btn-success" onClick={setNewUser}>
            Add another user
          </Button>
        </div>
      ) : (
        <div>
          <form onSubmit={createUser}>
            <br />
            <FormFieldInput name="username" value={userState.username} onChange={onChange}/>
            <FormFieldInput name="password" type="password" value={userState.password} onChange={onChange}/>
            <br /> <br />
            <FormFieldInput name="name" value={userState.name} onChange={onChange}/>
            <FormFieldInput name="email_id" value={userState.email_id} onChange={onChange}/>
            <FormFieldInput name="contact_number" value={userState.contact_number} onChange={onChange}/>
            <br /> <br />
            <FormFieldDropDown name="is_admin" value={userState.is_admin} items={[false, true]} onChange={onChange}/>
            <FormFieldDropDown name="age" value={userState.age} items={Array.from({length: 7}, (_, i) => i + 13)} onChange={onChange}/>
            <FormFieldDropDown name="grade" value={userState.grade} items={supportedGrades} onChange={onChange}/>
            <br /> <br />

            <br /> <br /> 
            <Button type="submit" className="btn btn-success">
              Create new user
            </Button>

            <br /> <br /> 
          </form>
        </div>
      )}
    </div>
  );
}

export default CreateUser;
