import React, { useEffect, useState } from "react";
import { Button, TextField } from '@mui/material';
import backendAPI, { makeSubmissionAPI } from '../backend-api/BackendAPI';
import Feedback from "./FeedbackIFrame";
import { defaultLevel, defaultWordLimit, wordLimitOptions, defaultPrompt, wordLimitLowerBoundRatio } from '../constants/constants';
import { findNumberOfWords, getUserDetails, preventPasting } from '../utils/GeneralUtils';
import { getAuthHeader } from '../utils/AuthUtils';
import { FormFieldDropDown } from '../utils/FormFields';
import { getPromptsByGradeAPI } from '../backend-api/BackendAPI';
import PromptsList, { validPrompts } from "./PromptsList";
import { useLocation } from "react-router-dom";
import { ISubmission } from "../interfaces/DataModels";
import { preloadSubmission } from "./PreloadSubmission";
import { getPrompts, makeSubmission } from "./WritingPageAPIHelper";
import AssignmentTypeDropDown, { AssignmentType } from "./AssignmentTypeDropDown";
import InstructionText from "./InstructionText";
import Grid from '@mui/material/Grid';

const defaultPromptData = {
  prompt: defaultPrompt,
  prompt_id: defaultPrompt.prompt_id,
  prompts: [defaultPrompt]
};

function WritingPage() {
  const location = useLocation();
  const locationState = location.state;
  const wasSubmissionPreloaded = (): boolean => {
    return location.state != null;
  }

  const [submissionText, setSubmissionText] = useState("");

  let initialWordLimit = defaultWordLimit;
  if (wasSubmissionPreloaded()) {
    // @ts-ignore
    initialWordLimit = location.state.submission.word_limit;
  }
  const [wordLimit, setWordLimit] = useState(initialWordLimit);

  const [feedbackURL, setFeedbackURL] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [promptData, setPromptData] = useState(defaultPromptData);
  const [submissionPreloadComplete, setSubmissionPreloadComplete] = useState<boolean>(false);
  const [assignmentType, setAssignmentType] = useState<AssignmentType>(AssignmentType.All);

  useEffect(() => {
    console.log("Location: "); console.log(location);
    console.log(`Location state: `); console.log(locationState);
    try {
      // @ts-ignore
      const submissionToPreload: ISubmission = locationState!.submission!;
      preloadSubmission(submissionToPreload, setWordLimit, setFeedbackURL, 
                        setShowFeedback, setIsLoading, setSubmissionText,);
    } catch (error) {
      console.log("Caught an error when trying to preload a submission.");
    }
  }, []);

  const deselectPrompt = () => {
    setPromptData({
      ...promptData,
      prompt: defaultPrompt,
      prompt_id: defaultPrompt.prompt_id,
    });
  }

  const onWordLimitChange = (event) => {
    setPromptData(defaultPromptData);
    setWordLimit(event.target.value);
  }

  const onAssignmentTypeChange = (event) => {
    deselectPrompt();
    setAssignmentType(event.target.value);
  }

  useEffect(() => {
    getPrompts(wordLimit, promptData, setPromptData);
  }, [wordLimit]);

  const handleSubmit = (text: string, wordLimit: number) => {
    console.log("Entered handleSubmit()...");
    setShowFeedback(false);

    const numWords = findNumberOfWords(text);
    if (numWords > wordLimit) {
      alert("You've exceeded the word limit! Your essay must be within the word limit to be submitted.");
      return;
    }

    setIsLoading(true);
    const data = {
      text: text
    };

    const userDetails = getUserDetails();
    const config = {
      headers: getAuthHeader(),
      params: {
        grade: userDetails.grade,
        prompt_id: promptData.prompt_id,
        prompt_heading: promptData.prompt.heading,
        word_limit: wordLimit,
        is_summary: promptData.prompt.is_summary,
      }
    };

    console.log("Data to send to backend for feedback: ");
    console.log(data);
    makeSubmission(data, config, setIsLoading, setFeedbackURL, setShowFeedback);
  }

  const isWordLimitExceeded = (): boolean => {
    const numWords = findNumberOfWords(submissionText);
    console.log(`${numWords} words in the textbox; word limit is ${wordLimit}`);
    return numWords > wordLimit;
  }

  const getWordLimitLowerBound = (): number => {
    return wordLimit * wordLimitLowerBoundRatio;
  }

  const areEnoughWordsWritten = (): boolean => {
    const numWords = findNumberOfWords(submissionText);
    const wordLimitLowerBound = getWordLimitLowerBound();
    console.log(`${numWords} words in the textbox; word limit lower bound is ${wordLimitLowerBound}`);
    return numWords >= wordLimitLowerBound;
  }

  const isWordCountValid = (): boolean => {
    return areEnoughWordsWritten() && !isWordLimitExceeded();
  }

  const isAPromptSelected = (): boolean => {
    return promptData.prompt_id != defaultPrompt.prompt_id;
  }

  const areAnyPromptsAvailable = (): boolean => {
    return validPrompts(promptData.prompts, assignmentType).length > 0;
  }

  useEffect(() => {
    // Update word limit according to the prompt selected.
    const selectedPrompt = promptData.prompts.find(prompt => prompt.prompt_id == promptData.prompt_id);
    if (selectedPrompt) {
      console.log("Selected prompt: "); console.log(selectedPrompt);
      // setWordLimit(selectedPrompt.word_limit);
      setPromptData({
        ...promptData,
        prompt: selectedPrompt
      })
    }
  }, [promptData.prompt_id]);

  const preloadPrompt = (fetchedPrompts) => {
    console.log("Preloading prompt...");
    // @ts-ignore
    const preloadedPromptId = location.state.submission.prompt_id;
    console.log(`Location pid: ${preloadedPromptId}`);
    const selectedPrompt = fetchedPrompts.find(prompt => prompt.prompt_id == preloadedPromptId);
    console.log("Selected prompt: "); console.log(selectedPrompt);
    setPromptData({
      ...promptData,
      prompts: fetchedPrompts,
      prompt_id: preloadedPromptId,
      prompt: selectedPrompt!
    });
  }

  if (wasSubmissionPreloaded() && !submissionPreloadComplete) {
    // preloadPrompt(promptData.prompts);
    // @ts-ignore
    const preloadedPromptId = location.state.submission.prompt_id;
    console.log(`Location pid: ${preloadedPromptId}`);
    const selectedPrompt = promptData.prompts.find(prompt => prompt.prompt_id == preloadedPromptId);
    console.log("Selected prompt: "); console.log(selectedPrompt);
    if (selectedPrompt) {
      setPromptData({
        ...promptData,
        prompt_id: preloadedPromptId,
        prompt: selectedPrompt!
      });
      setSubmissionPreloadComplete(true);
    }
  }

  return (
    <Grid className="Writingpage" container spacing={2}>
      <Grid className="Writingpage-details" item xs={12} md={6}>
        <FormFieldDropDown name="wordLimit" value={wordLimit} items={wordLimitOptions} onChange={onWordLimitChange} />
        <AssignmentTypeDropDown name="assignmentType" value={assignmentType} onChange={onAssignmentTypeChange} />
        {wordLimit != defaultPrompt.word_limit && <PromptsList promptData={promptData} setPromptData={setPromptData} assignmentType={assignmentType} />}

        {!areAnyPromptsAvailable() && <h3 style={{color: "red"}}>No topics are available for this word limit in your grade.</h3>}
        <InstructionText
          isAPromptSelected={isAPromptSelected}
          promptData={promptData}
          getWordLimitLowerBound={getWordLimitLowerBound}
          wordLimit={wordLimit}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button className="Submitbutton" variant="outlined"
          disabled={promptData.prompt_id == defaultPrompt.prompt_id || !isWordCountValid() || isLoading}
          onClick={() => handleSubmit(submissionText, wordLimit)}>
          Submit
        </Button>
        <TextField className="Writetextbox"
          // Pasting is temporarily allowed, only for testing.
          // onPaste={preventPasting}
          onChange={(event) => {
            const {value} = event.target;
            setSubmissionText(value);
          }}
          defaultValue={submissionText}
          error={isWordLimitExceeded()}
          helperText={`${findNumberOfWords(submissionText)}/${wordLimit}`}
          multiline 
          fullWidth
          margin="normal"
          minRows={5}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        { showFeedback ? <Feedback feedbackURL={feedbackURL} /> : null }
        { isLoading ? <h3 className="FeedbackWaitMessage">Waiting for feedback...</h3> : null}
        { showFeedback && !isLoading && 
          <p>
            To improve your score, correct any mistakes that were identified, 
            and use a broader vocabulary of words.
            <br />
            You can click any mistakes you made (by clicking on the parts struck through in red), 
            to view a hint describing the reasoning behind them. 
          </p>
        }
      </Grid>
    </Grid>
  )
}

export default WritingPage;
