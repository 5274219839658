import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import React, { useState } from "react";
import { wordLimitOptions } from "../constants/constants";

export const WritingToolDataGrid = (props: any) => {
  const [pageSize, setPageSize] = useState(5);

  return (
    <DataGrid
      {...props}
      // autoPageSize
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      disableSelectionOnClick
    />
  )
}

export const WordLimitGridColumn = {
  field: 'word_limit',
  headerName: 'Word Limit',
  width: 150,
  filterOperators: getGridSingleSelectOperators(),
  valueOptions: wordLimitOptions,
};

export const AssignmentTypeGridColumn = {
  field: 'is_summary',
  headerName: 'Assignment Type',
  width: 180,
  valueGetter: (params) => params.row.is_summary ? 'Summary' : 'Essay',
  filterOperators: getGridSingleSelectOperators(),
  valueOptions: ['Essay', 'Summary'],
}
