import React from 'react';

const Feedback = (props: any) => (
  <div id="Feedback" >
    <a id="Feedback-anchor"></a>
    <iframe 
      ref={this}
      src={props.feedbackURL} 
      width="100%" 
      height="100%" 
      onLoad={() => {location.assign("#Feedback-anchor");}} 
    />
  </div>
)

export default Feedback;
