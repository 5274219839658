import React from "react";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { convertTimeValueToDate } from '../../utils/GeneralUtils';
import { defaultPrompt, changePasswordURL } from '../../constants/constants';
import { IUser } from "../../interfaces/DataModels";
import { Link } from "react-router-dom";

interface IProps {
  rows: Array<IUser>;
  // setSelectedStudent: any;
};

const AdminDashboardTable = (props: IProps) => {
  const gridColumns: GridColDef[] = [
    { 
      field: 'id', headerName: 'ID', width: 90, hide: true
    },
    {
      field: 'username',
      headerName: 'Username',
      width: 200,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'email_id',
      headerName: 'Email',
      width: 300,
    },
    {
      field: 'contact_number',
      headerName: 'Contact No.',
      width: 150,
    },
    {
      field: 'age',
      headerName: 'Age',
      width: 80,
    },
    {
      field: 'change_password',
      headerName: 'Change Password',
      renderCell: (params: any) => (
        <Link to={changePasswordURL} state={{username: params.row.username}}>
          <Button>
            Change Password
          </Button>
        </Link>
      ),
      width: 180,
    },
  ];


  const gridRows = props.rows.map(row => ({
    id: row.username,
    ...row,
  }));

  console.log("Grid rows: "); console.log(gridRows);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid 
        rows={gridRows}
        columns={gridColumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </div>
  );
}

export default AdminDashboardTable;
