import React from "react";
import { getGridSingleSelectOperators, GridColDef } from "@mui/x-data-grid";
import { v4 as uuidv4 } from 'uuid';
import { WordLimitGridColumn, WritingToolDataGrid, AssignmentTypeGridColumn } from '../utils/WritingToolDataGrid';
import { getUserDetails } from '../utils/GeneralUtils';
import { supportedGrades } from '../constants/constants';
import DeletePromptButton from "../create-prompt/DeletePromptButton";

const GradePromptsTable = (props: any) => {
  const gridColumns: GridColDef[] = [
    { 
      field: 'id', headerName: 'ID', width: 90, hide: true,
      valueGetter: (params) => uuidv4(),
    },
    {
      field: 'grade',
      headerName: 'Grade',
      width: 100,
      filterOperators: getGridSingleSelectOperators(),
      valueOptions: supportedGrades.map(String),
    },
    AssignmentTypeGridColumn,
    {
      field: 'heading',
      headerName: 'Heading',
      width: 250,
    },
    {
      field: 'description',
      headerName: 'Description (Summary text link for summary assignments)',
      width: 750,
      renderCell: (params: any) => {
        if (params.row.is_summary) {
          return <a href={params.row.description} target="_blank">Text to Summarize - Link</a>;
        } else {
          return params.row.description;
        }
      },
    },
    WordLimitGridColumn,
  ];

  const {is_admin} = getUserDetails();
  if (is_admin) {
    gridColumns.push({
      field: 'delete_prompt',
      headerName: 'Delete Topic',
      width: 150,
      renderCell: (params: any) => (
        <DeletePromptButton prompt={params.row}/>
      ),
    })
  }

  const gridRows = props.rows.map(row => {
    return {
      id: uuidv4(),
      ...row
    }
  });

  return (
    <div style={{ height: 400, width: '100%' }}>
      <WritingToolDataGrid
        rows={gridRows}
        columns={gridColumns}
      />
    </div>
  );
}

export default GradePromptsTable;
