import React, { useEffect, useState } from "react";
import { FormFieldDropDown } from "../utils/FormFields";
import { supportedGrades, wordLimitOptions } from '../constants/constants';
import PromptsForGrade from "./PromptsForGrade";

const ViewPrompts = () => {
  const [grade, setGrade] = useState("");
  const [wordLimit, setWordLimit] = useState("");

  return (
    <div className="ViewPrompts">
      <FormFieldDropDown name="grade" value={grade} items={supportedGrades} onChange={(event) => {
        setGrade(event.target.value);
      }} />
      <FormFieldDropDown name="wordLimit" value={wordLimit} items={wordLimitOptions} onChange={(event) => {
        setWordLimit(event.target.value);
      }} />
      {grade != "" && wordLimit != "" && <PromptsForGrade grade={grade} wordLimit={wordLimit} />}
    </div>
  )
}

export default ViewPrompts;
