import { Button } from "@mui/material";
import React from "react";
import { IPrompt } from '../interfaces/DataModels';
import { deletePrompt } from "./DeletePrompt";

interface IProps {
  prompt: IPrompt;
};

const DeletePromptButton = (props: IProps) => {
  return (
    <Button onClick={() => deletePrompt(props.prompt)}>
      Delete Topic
    </Button>
  );
}

export default DeletePromptButton;
