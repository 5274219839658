export interface IUserDetails {
  name: string;
  username: string;
  school_id: string;
  grade: number | string;
  is_admin: boolean;
  email_id: string;
  contact_number: string;
  age: number;
};

const noUserFoundString = "No user logged in.";
export const defaultUserDetails: IUserDetails = {
  name: noUserFoundString,
  username: noUserFoundString,
  school_id: noUserFoundString,
  grade: noUserFoundString,
  is_admin: false,
  email_id: noUserFoundString,
  contact_number: noUserFoundString,
  age: 0,
};
