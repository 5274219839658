import React, { useEffect, useState } from 'react';
import backendAPI, { getPromptsByGradeAPI, submissionsAPI } from '../../backend-api/BackendAPI';
import { getAuthHeader } from '../../utils/AuthUtils';
import { getUserDetails } from '../../utils/GeneralUtils';
import StudentDashboardTable from './StudentDashboardTable';
import { defaultPrompt, defaultSubmission, wordLimitOptions, defaultWordLimit } from '../../constants/constants';
import { ISubmission } from '../../interfaces/DataModels';
import { FormFieldDropDown } from '../../utils/FormFields';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';

interface IProps {
  username?: string;
};

const StudentDashboard = (props: IProps) => {
  const [submissions, setSubmissions] = useState<Array<ISubmission>>([]);
  const [prompts, setPrompts] = useState([defaultPrompt]);
  const [userDetails, setUserDetails] = useState(getUserDetails());
  const [wordLimit, setWordLimit] = useState<number>(defaultWordLimit);

  const getSubmissions = () => {
    let config = {
      headers: getAuthHeader()
    };
    console.log("Props.username: "); console.log(props.username);
    if (props.username) {
      config['params'] = {
        username: props.username
      };
    }
    console.log("GET submissions Config: "); console.log(config);
    backendAPI.get(submissionsAPI, config)
    .then(response => {
      console.log("Response data: "); console.log(response.data);
      setSubmissions(response.data);
    })
    .catch(e => {
      console.log("Something went wrong."); console.log(e);
      console.log('Failed to get submissions.');
    });
  }

  const getPrompts = () => {
    if (!wordLimit) {
      return;
    }
    const userDetails = getUserDetails();
    const config = {
      headers: getAuthHeader(),
      params: {
        grade: userDetails.grade,
        word_limit: wordLimit
      }
    };
    backendAPI.get(getPromptsByGradeAPI, config)
    .then(response => {
      console.log("Retrieved prompts for grade: " + userDetails.grade + " successfully.");
      console.log("Response data: "); console.log(response.data);
      setPrompts(response.data);
    })
    .catch(e => {
      console.log("Something went wrong."); console.log(e);
    });
  }

  useEffect(() => {
    getSubmissions();
    getPrompts();
    setUserDetails(getUserDetails());
  }, []);
  useEffect(() => {
    getPrompts();
  }, [wordLimit]);

  const onWordLimitChange = (event) => {
    const {value} = event.target;
    setWordLimit(value);
  }

  let randomSubmissions: Array<ISubmission> = [];
  for (let i = 0; i < 30; i += 1) {
    randomSubmissions.push(defaultSubmission);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="Name">Name</InputLabel>
        <div className="Block-text">{userDetails.name}</div>
      </Grid>
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="Proficiency">Proficiency</InputLabel>
        <div className="Block-text">Grade {userDetails.grade}</div>
      </Grid>
      <Grid item xs={0} md={6} />
      <Grid item xs={12}>
        {console.log("Submissions: ")} {console.log(submissions)}
        {console.log("Prompts: ")} {console.log(prompts)}
        {
          (submissions == [defaultSubmission] && prompts == [defaultPrompt]) 
            ? (<h4>Loading...</h4>)
            : (<StudentDashboardTable key={toString()} rows={submissions} prompts={prompts}/>)
        }
      </Grid>
    </Grid>
  );
}

export default StudentDashboard;
