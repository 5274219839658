import React from "react";
import { getUserDetails } from '../../utils/GeneralUtils';
import AdminDashboard from "../admin-dashboard/AdminDashboard";
import StudentDashboard from "./StudentDashboard";

const DashboardSelector = () => {
  const {is_admin} = getUserDetails();
  if (is_admin) {
    return <AdminDashboard />;
  } else {
    return <StudentDashboard />;
  }
}

export default DashboardSelector;
