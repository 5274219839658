import React, { useEffect, useState } from "react";
import backendAPI from "../backend-api/BackendAPI";
import { quicksightDashboardAPI } from '../backend-api/BackendAPI';
import { getAuthHeader } from "../utils/AuthUtils";
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

const PerformanceDashboard = () => {
  const [quicksightDashboardURL, setQuicksightDashboardURL] = useState<string>();

  const dashboardRef = React.createRef();
  let isDashboardLoaded = false;

  const embed = (quicksightURL: string) => {
    const options = {
      url: quicksightURL,
      container: document.getElementById("QuickSightDashboard"),
      scrolling: "no",
      height: "100%",
      width: "100%",
      locale: "en-US",
      footerPaddingEnabled: true,
      sheetId: 'Sheet 1', // use this option to specify initial sheet id to load for the embedded dashboard
      sheetTabsDisabled: true, // use this option to enable or disable sheet tab controls in dashboard embedding
    };
    embedDashboard(options);
  }

  const getQuicksightDashboardURL = () => {
    const config = {
      headers: getAuthHeader(),
    };
    backendAPI.get(quicksightDashboardAPI, config)
    .then(response => {
      console.log("Response data: "); console.log(response.data);
      setQuicksightDashboardURL(response.data);
    })
    .catch(e => {
      console.log("Something went wrong."); console.log(e);
    });
  }

  useEffect(() => {
    getQuicksightDashboardURL();
  }, []);
  useEffect(() => {
    console.log(`QuickSight URL: ${quicksightDashboardURL}`);
    if (quicksightDashboardURL && !isDashboardLoaded) {
      isDashboardLoaded = true;
      embed(quicksightDashboardURL);
    }
  }, [quicksightDashboardURL]);
  return (
    <div id="PerformanceDashboard">
      { quicksightDashboardURL 
        ? <div id="QuickSightDashboard" />
        : <h3>Loading...</h3> 
      }
    </div>
  )
}

export default PerformanceDashboard;
