import { Button, Grid } from '@mui/material';
import React from 'react';
import { wordLimitOptions } from '../constants/constants';
import { FormFieldDropDown, FormFieldInput, FormFieldTextBox } from '../utils/FormFields';
import { ISummaryState } from './CreateSummaryAssignment';

interface IProps {
  createSummary: (event: any) => void;
  summaryState: ISummaryState;
  onChange: (event: any) => void;
  onClick: (event: any) => void;
  areConceptsLoaded: boolean | undefined;
  getConcepts: (event: any) => void;
  textConcepts: string[];
  allowedGrades: number[];
};

const CreateSummaryAssignmentForm = (props: IProps) => {
  const {createSummary, summaryState, onChange, onClick, areConceptsLoaded, getConcepts, textConcepts, allowedGrades} = props;
  console.log(`Word Limits: ${summaryState.wordLimits}`);

  return (
    <div className="CreateSummaryAssignmentForm">
      <form onSubmit={createSummary}>

        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <FormFieldDropDown name="grade" value={summaryState.grade} items={allowedGrades} onChange={onChange}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormFieldDropDown multiple name="wordLimits" value={summaryState.wordLimits} items={wordLimitOptions} onChange={onChange}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormFieldInput name="heading" value={summaryState.heading} onChange={onChange} style={{ minWidth: 400 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormFieldTextBox 
              name="description" 
              value={summaryState.description} 
              onChange={onChange} 
              onClick={onClick}
              helperText="Enter the text to summarize here."
              maxRows={20}
              disabled={areConceptsLoaded}
            />
          </Grid>
        </Grid>

        <br /> 
        {
          !areConceptsLoaded && 
          <Button type="submit" className="btn btn-success" onClick={getConcepts}>
            Get Concepts
          </Button>
        }

        {
          areConceptsLoaded && (
            <div>
              <FormFieldDropDown multiple name="concepts" value={summaryState.concepts} items={textConcepts} onChange={onChange} sx={{ minWidth: 180 }}/>
              <br /> <br />
              <Button type="submit" className="btn btn-success">
                Create Summary Assignment
              </Button>
            </div>
          )
        }

      </form>
    </div>
  )
}

export default CreateSummaryAssignmentForm;
