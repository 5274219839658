import axios from 'axios';
import { logOutUser } from '../login-page/LoginPage';

const backendAPI = axios.create({
    baseURL: 'https://ti-writing-tool-backend.stage2.cnu-tu.ey.io',
    // baseURL: 'http://localhost:8000',
    timeout: 100000 // 100 seconds
});
backendAPI.interceptors.response.use((response) => response, (error) => {
  // Add any default error handling here.
  console.log("Error intercepted.");
  console.log(error.response);
  try {
    console.log(error.response.data.detail);
  } catch (dummy) {}
  const statusCode = error.response.status;
  console.log(`Error status code: ${statusCode}`);
  if (statusCode == 401) {
    logOutUser();
  }
  throw error;
});

export default backendAPI;

// Backend API Endpoints.

export const loginAPI = 'login';
export const changePasswordAPI = 'password';

export const makeSubmissionAPI = 'submit';
export const submissionsAPI = 'submissions';

export const getUserInfoAPI = 'info';
export const getUsersAPI = 'users';
export const createUserAPI = 'user/create';

export const createPromptAPI = 'prompt/create';
export const getPromptsByGradeAPI = 'prompt';
export const getCreatedPromptsAPI = 'prompt/created';
export const getConceptsAPI = 'prompt/concepts';
export const deletePromptAPI = 'prompt';

export const quicksightDashboardAPI = 'dashboard';
