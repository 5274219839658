import React from "react";
import { Button } from '@mui/material';
import { getGridDateOperators, getGridNumericColumnOperators, getGridSingleSelectOperators, GridColDef } from '@mui/x-data-grid';
import { convertTimeValueToDate } from '../../utils/GeneralUtils';
import { writingURL } from '../../constants/constants';
import { Link } from "react-router-dom";
import { AssignmentTypeGridColumn, WordLimitGridColumn, WritingToolDataGrid } from "../../utils/WritingToolDataGrid";

const StudentDashboardTable = (props: any) => {
  console.log("Props prompts: "); console.log(props.prompts);
  const gridColumns: GridColDef[] = [
    {
      field: 'id', headerName: 'ID', width: 90, hide: true
    }, 
    AssignmentTypeGridColumn, 
    {
      field: 'prompt_heading',
      headerName: 'Topic',
      width: 300,
    }, 
    WordLimitGridColumn, 
    {
      field: 'submission_time_utc',
      headerName: 'Submission Time',
      width: 400,
      valueGetter: (params) => convertTimeValueToDate(params.row.submission_time),
      filterOperators: getGridDateOperators(),
    }, 
    {
      field: 'overall_score',
      headerName: 'Score',
      width: 150,
      filterOperators: getGridNumericColumnOperators(),
    }, 
    {
      field: 'rewrite_submission',
      headerName: 'Attempt Again',
      renderCell: (params: any) => (
        <Link to={writingURL} state={{submission: params.row}}>
          <Button>
            Load Submission
          </Button>
          {console.log("Params: ")} {console.log(params.row)}
        </Link>
      ),
      width: 180,
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const gridRows = props.rows.map(row => {
    const { submission_time } = row;
    return {
      id: submission_time,
      ...row,
    }
  });

  console.log("Grid rows: "); console.log(gridRows);

  return (
    <div className="StudentDashboardTable">
      <WritingToolDataGrid
        rows={gridRows}
        columns={gridColumns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'submission_time_utc', sort: 'desc' }],
          },
        }}
      />
    </div>
  );
}

export default StudentDashboardTable;
