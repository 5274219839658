import React, { useEffect, useState } from 'react';
import backendAPI, { getPromptsByGradeAPI, submissionsAPI } from '../../backend-api/BackendAPI';
import { getAuthHeader } from '../../utils/AuthUtils';
import { getUserDetails } from '../../utils/GeneralUtils';
import StudentDashboardTable from '../student-dashboard/StudentDashboardTable';
import { defaultPrompt, defaultSubmission, wordLimitOptions, supportedGrades, createUserURL, defaultGrade } from '../../constants/constants';
import { ISubmission, IUser } from '../../interfaces/DataModels';
import { FormFieldDropDown } from '../../utils/FormFields';
import { getUsersAPI } from '../../backend-api/BackendAPI';
import AdminDashboardTable from './AdminDashboardTable';
import { Button, Grid, InputLabel } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const [schools, setSchools] = useState<Array<any>>([]);
  const [grade, setGrade] = useState<number>(defaultGrade);
  const [students, setStudents] = useState<Array<IUser>>([]);
  const userDetails = getUserDetails();
  const navigate = useNavigate();

  const getStudents = () => {
    let config = {
      headers: getAuthHeader(),
      params: {
        grade: grade,
        school_id: userDetails.school_id,
      }
    };
    backendAPI.get(getUsersAPI, config)
    .then(response => {
      console.log("Response data: "); console.log(response.data);
      setStudents(response.data);
    })
    .catch(e => {
      console.log("Something went wrong."); console.log(e);
      console.log('Failed to get students.');
    });
  }

  useEffect(() => { // On initial render.
    // getSchools();
  }, []);
  useEffect(() => {
    setStudents([]);
    getStudents();
  }, [grade]);

  return (
    <div className="AdminDashboard">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h1>Admin Dashboard</h1>
        </Grid>
        <Grid item xs={12} md={3}>
          <InputLabel htmlFor="Name">Name</InputLabel>
          <div className="Block-text">{userDetails.name}</div>
        </Grid>
        <Grid item xs={12} md={3}>
          <InputLabel htmlFor="School">School</InputLabel>
          <div className="Block-text">{userDetails.school_id}</div>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <FormFieldDropDown name="school" value={school} items={schools} onChange={onSchoolChange}/> */}
          <FormFieldDropDown name="grade" value={grade} items={supportedGrades} onChange={(event) => setGrade(event.target.value)}/>
          <h2>Student List (Grade: {grade})</h2>
          {console.log("students: ")} {console.log(students)}
          {
            (students == []) 
              ? (<h4>Loading...</h4>)
              : (<div>
                  <AdminDashboardTable key={toString()} rows={students.filter(student => !student.is_admin)}/>
                  <Button onClick={() => navigate(createUserURL)}>Create New User</Button> 
                </div>)
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminDashboard;
