import axios from "axios";
import { ISubmission } from "../interfaces/DataModels";
import { fetchTextFromS3 } from '../utils/GeneralUtils';

export const preloadSubmissionText = (
  submissionURL: string, 
  setSubmissionText: React.Dispatch<React.SetStateAction<string>>
) => {
  fetchTextFromS3(submissionURL, setSubmissionText);
}

export const preloadSubmission = (
  submission: ISubmission,
  setWordLimit: React.Dispatch<React.SetStateAction<number>>,
  setFeedbackURL: React.Dispatch<React.SetStateAction<string>>,
  setShowFeedback: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSubmissionText: React.Dispatch<React.SetStateAction<string>>
) => {
  console.log("Submission to preload: "); console.log(submission);
  const submissionURL = submission.submission_url;
  console.log(`Submission URL: ${submissionURL}`);

  // Set the word limit. This will triger the getPrompts call.
  setWordLimit(submission.word_limit);

  // Set the feedback URL.
  setFeedbackURL(submission.feedback_url);
  setShowFeedback(true);
  setIsLoading(false);

  // Set the submission text.
  preloadSubmissionText(submissionURL, setSubmissionText);
}
