import { ISubmission, IUser, IPrompt } from '../interfaces/DataModels';
export const defaultLevel = 10;
export const wordLimitOptions = [10, 50, 100, 250, 500, 1000];
export const defaultWordLimit = wordLimitOptions.at(-1)!;
export const supportedGrades = [9, 10, 11, 12];
export const defaultGrade = supportedGrades.at(-1)!;
export const wordLimitLowerBoundRatio = 0.70;  // Students must write at least 80% of the word limit.
export const minSummaryConceptNum = 3;

export const authTokenKey = 'token';
export const userDetailsKey = 'userDetails';

export const dashboardURL = '/dashboard';
export const loginURL = '/login';
export const changePasswordURL = '/password/change';
export const createUserURL = '/user/create';
export const writingURL = '/writing';
export const adminURL = '/admin';
export const viewPromptsURL = '/view_prompts';
export const createPromptURL = '/create_prompt';
export const performanceURL = '/performance';

export const defaultPrompt: IPrompt = {
  prompt_id: "-1",
  grade: "0",
  heading: "No prompt selected",
  description: "No prompt selected",
  word_limit: 0,
  is_summary: false,
  school_id: "No prompt selected",
};
export const defaultPromptList: IPrompt[] = [defaultPrompt];

const loadingMessage = "loading...";
export const defaultSubmission: ISubmission = {
  submission_time: loadingMessage,
  prompt_id: loadingMessage,
  submission_url: loadingMessage,
  feedback_url: loadingMessage,
  score: loadingMessage,
  grade: loadingMessage,
  school_id: loadingMessage,
  word_limit: 0,
};

export const blankUser: IUser = {
  username: ``,
  password: ``,
  name: ``,
  email_id: ``,
  contact_number: ``,
  is_admin: false,
  school_id: ``,
  age: 0,
  grade: ``,
}
