import React, { useEffect, useState } from 'react';
import backendAPI, { createPromptAPI, getConceptsAPI } from '../backend-api/BackendAPI';
import { v4 as uuidv4 } from 'uuid';
import { getAuthHeader } from '../utils/AuthUtils';
import { getUserDetails } from '../utils/GeneralUtils';
import CreateSummaryAssignmentForm from './CreateSummaryAssignmentForm';
import { wordLimitOptions, minSummaryConceptNum } from '../constants/constants';

export interface ISummaryState {
  id: string | null;
  grade: string;
  heading: string;
  description: string;
  wordLimit: number | string;
  wordLimits: number[];
  concepts: string[];
  username: string | null;
  submitted: boolean;
};

interface IProps {
  allowedGrades: number[];
  setPromptState: React.Dispatch<React.SetStateAction<any>>;
}

const CreateSummaryAssignment = (props: IProps) => {
  const defaultSummaryState: ISummaryState = {
    id: null,
    grade: ``,
    heading: "",
    description: "",
    wordLimit: ``,
    wordLimits: [],
    concepts: [],
    username: localStorage.getItem('username'),
    submitted: false
  };
  const [summaryState, setSummaryState] = useState(defaultSummaryState);
  const [textConcepts, setTextConcepts] = useState<string[]>([]);
  const [areConceptsLoaded, setAreConceptsLoaded] = useState<boolean>();

  const onChange = (event) => {
    const {name, value} = event.target;
    setSummaryState({
      ...summaryState,
      [name]: value,
    });
    props.setPromptState({
      ...summaryState,
      [name]: value,
    });
  }

  const onTextFieldClick = (event) => {
    if (areConceptsLoaded) {
      const refresh = confirm("If you wish to modify the text to be summarized, you'll lose your changes. Do you wish to proceed?");
      if (refresh) {
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    if (summaryState.wordLimits.length > 0) {
      setSummaryState({
        ...summaryState,
        wordLimit: summaryState.wordLimits[0]
      });
      props.setPromptState({
        ...summaryState,
        wordLimit: summaryState.wordLimits[0]
      })
    }
  }, [summaryState.wordLimits]);

  const createSummary = (event) => {
    event.preventDefault();
    console.log(`Attempting to create summary assignments with word limits: ${summaryState.wordLimits}`);

    // Check if the minimum number of concepts has been selected.
    if (summaryState.concepts.length < minSummaryConceptNum) {
      alert(`Cannot create summary assignment: You must select at least ${minSummaryConceptNum} concepts to proceed.`)
      return;
    }

    summaryState.wordLimits.forEach(wordLimit => createSummaryWithWordLimit(wordLimit));
  }

  const createSummaryWithWordLimit = (wordLimit: number) => {
    console.log(`Attempting to send request to create summary assignment with word limit: ${wordLimit}.`);
    const newPromptId = uuidv4();
    const userDetails = getUserDetails();
    const current_timestamp = new Date();
    const data = {
      prompt_id: newPromptId,
      username: userDetails.username,
      school_id: userDetails.school_id,
      grade: summaryState.grade,
      heading: summaryState.heading,
      description: summaryState.description,
      word_limit: wordLimit,
      creation_time: current_timestamp,
      is_summary: true,
      concepts: summaryState.concepts,
    };
    const config = {
      headers: getAuthHeader()
    };
    console.log("Create summary prompt data: "); console.log(data);
    backendAPI.post(createPromptAPI, data, config)
      .then(response => {
        console.log("Success: Summary Prompt created.")
        setSummaryState({
          ...summaryState,
          submitted: true
        });
        props.setPromptState({
          ...summaryState,
          submitted: true
        })
        console.log(response.data);
        alert(`New summary topic with word limit: ${wordLimit} created successfully.`);
      })
      .catch(error => {
        console.log("Failure: Summary Prompt not created.");
        console.log(error.response);
        const errorMessage = "Summary Topic not created. "
        try {
          errorMessage.concat(error.response.data.detail);
        } catch (dummy) {}
        alert(errorMessage);
      });
  }

  const getConcepts = (event) => {
    console.log("Attempting to get concepts for summary text.");
    console.log(event);
    event.preventDefault();
    const data = {
      text: summaryState.description
    };
    console.log("Data to get concepts on: "); console.log(data);
    const config = {
      headers: getAuthHeader(),
    };
    backendAPI.post(getConceptsAPI, data, config)
      .then(response => {
        console.log("Response data: "); console.log(response.data);
        setTextConcepts(response.data);
        setAreConceptsLoaded(true);
      })
      .catch(e => {
        console.log("Something went wrong."); console.log(e);
      });
  }

  return (
    <CreateSummaryAssignmentForm
      createSummary={createSummary}
      summaryState={summaryState}
      onChange={onChange}
      onClick={onTextFieldClick}
      areConceptsLoaded={areConceptsLoaded}
      getConcepts={getConcepts}
      textConcepts={textConcepts}
      allowedGrades={props.allowedGrades}
    />
  )
}

export default CreateSummaryAssignment;
