import { createTheme } from "@mui/material";
import { green, purple, red } from "@mui/material/colors";

export const writingToolTheme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: green[600],
    },
  },
});
