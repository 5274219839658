import { InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import { IPrompt } from "../interfaces/DataModels";
import { AssignmentType } from "./AssignmentTypeDropDown";

interface IProps {
  promptData: any;
  setPromptData: React.Dispatch<React.SetStateAction<any>>;
  assignmentType: AssignmentType;
};

export const validPrompts = (prompts: IPrompt[], assignmentType: AssignmentType): IPrompt[] => {
  switch (assignmentType) {
    case AssignmentType.All: 
      return prompts;
    case AssignmentType.Essay: 
      return prompts.filter(prompt => !prompt.is_summary);
    case AssignmentType.Summary: 
      return prompts.filter(prompt => prompt.is_summary);
  }
}

const PromptsList = (props: IProps) => {
  const {promptData, setPromptData, assignmentType} = props;
  const noOptions: boolean = (!promptData.prompts || validPrompts(promptData.prompts, assignmentType).length == 0);
  console.log(`No options in drop down for prompts? ${noOptions}`);
  console.log(`In PromptsList, prompt id: ${promptData.prompt_id}`);

  const setDefaultPrompt = () => {
    const defaultPrompt = validPrompts(promptData.prompts, assignmentType)[0];
    console.log("Default prompt: "); console.log(defaultPrompt);
    if (defaultPrompt) {
      setPromptData({
        ...promptData,
        prompt_id: defaultPrompt.prompt_id,
      })
    }
  }

  useEffect(() => {
    setDefaultPrompt();
  }, [promptData.prompts, assignmentType]);

  return (
    <div className="form-group" style={{ display: 'inline-block', marginLeft: '20px', minWidth: 200 }}>
      <InputLabel htmlFor="prompt">Select a Topic</InputLabel>
      <Select defaultValue="None" value={promptData.prompt_id} name="prompt" id="prompt" sx={{ minWidth: 200 }} onChange={(event) => {
          const {value} = event.target;
          setPromptData({
            ...promptData,
            prompt_id: value
          });
        }}
        disabled={noOptions}
      >
        { noOptions && <MenuItem value='' selected><em>None</em></MenuItem> }
        {validPrompts(promptData.prompts, assignmentType)
          .map(prompt => (
            <MenuItem key={prompt.prompt_id} value={prompt.prompt_id}> {prompt.heading} </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default PromptsList;
