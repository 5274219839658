import { Box, Button, Grid } from '@mui/material';
import React, { useState } from 'react';

import backendAPI, { createPromptAPI } from '../backend-api/BackendAPI';
import { FormFieldDropDown, FormFieldInput, FormFieldTextBox } from '../utils/FormFields';
import { getAuthHeader } from '../utils/AuthUtils';
import { wordLimitOptions, supportedGrades } from '../constants/constants';
import { v4 as uuidv4 } from 'uuid';
import { getUserDetails } from '../utils/GeneralUtils';
import ViewCreatedPrompts from './ViewCreatedPrompts';
import ViewPrompts from '../view-prompts/ViewPrompts';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PromptsForGrade from '../view-prompts/PromptsForGrade';
import CreateSummaryAssignment from './CreateSummaryAssignment';

const CreatePrompt = (props: any) => {
  const defaultPromptState = {
    id: null,
    grade: ``,
    heading: "",
    description: "",
    wordLimit: ``,
    username: localStorage.getItem('username'),
    submitted: false
  };

  const [promptState, setPromptState] = useState(defaultPromptState);
  const userDetails = getUserDetails();
  let allowedGradesForPromptCreation: number[];
  if (userDetails.is_admin) {
    allowedGradesForPromptCreation = supportedGrades;
  } else {
    allowedGradesForPromptCreation = supportedGrades.filter(grade => grade < userDetails.grade);
  }

  const setNewPrompt = () => {
    setPromptState({
      ...promptState,
      ...defaultPromptState
    });
  }

  const onChange = (event) => {
    const {name, value} = event.target;
    setPromptState({
      ...promptState,
      [name]: value,
    });
  }

  const createPrompt = (event) => {
    event.preventDefault();
    const newPromptId = uuidv4();
    const userDetails = getUserDetails();
    const current_timestamp = new Date();
    const data = {
      prompt_id: newPromptId,
      username: userDetails.username,
      school_id: userDetails.school_id,
      grade: promptState.grade,
      heading: promptState.heading,
      description: promptState.description,
      word_limit: promptState.wordLimit,
      creation_time: current_timestamp,
    };
    const config = {
      headers: getAuthHeader()
    };
    console.log("Create prompt data: "); console.log(data);
    backendAPI.post(createPromptAPI, data, config)
      .then(response => {
        console.log("Success: Prompt created.")
        setPromptState({
          ...promptState,
          submitted: true
        });
        console.log(response.data);
        alert('New topic created successfully.');
      })
      .catch(error => {
        console.log("Failure: Prompt not created.");
        console.log(error.response);
        const errorMessage = "Topic not created. "
        try {
          errorMessage.concat(error.response.data.detail);
        } catch (dummy) {}
        alert(errorMessage);
      });
  }

  const isGradeAndWordLimitSelected = (): boolean => {
    return promptState.grade != defaultPromptState.grade && promptState.wordLimit != defaultPromptState.wordLimit
  }

  return (
    <div className="submit-form">
      {promptState.submitted ? (
        <div>
          <h4>Topic created successfully.</h4>
          <Button className="btn btn-success" onClick={setNewPrompt}>
            Add another topic
          </Button>
        </div>
      ) : (
        <div>
          <Tabs>
            <TabList>
              <Tab>Create Essay Assignment</Tab>
              <Tab>Create Summary Assignment</Tab>
            </TabList>
            <TabPanel>
              <form onSubmit={createPrompt}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={3}>
                    <FormFieldDropDown name="grade" value={promptState.grade} items={allowedGradesForPromptCreation} onChange={onChange}/>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormFieldDropDown name="wordLimit" value={promptState.wordLimit} items={wordLimitOptions} onChange={onChange}/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormFieldInput name="heading" value={promptState.heading} onChange={onChange} style={{ minWidth: 400 }} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormFieldTextBox className="PromptDescriptionTextBox" name="description" value={promptState.description} onChange={onChange}/>
                  </Grid>
                </Grid>

                <br />
                {
                  props.create ? (
                    <Button type="submit" className="btn btn-success">
                      Create new topic
                    </Button>
                    ) : (""
                    // <Button onClick={updatePrompt} className="btn btn-success">
                    //   Update prompt
                    // </Button>
                  )
                }
              </form>
            </TabPanel>
            <TabPanel>
              <CreateSummaryAssignment
                allowedGrades={allowedGradesForPromptCreation}
                setPromptState={setPromptState}
              />
            </TabPanel>
          </Tabs>
        </div>
      )}

      <br /> <br /> 

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs>
          <TabList>
            <Tab>Topics Created By Me</Tab>
            {
              isGradeAndWordLimitSelected()
                ? <Tab>Topics for Grade {promptState.grade} (word limit: {promptState.wordLimit})</Tab>
                : <Tab>Select a Grade and Word limit above</Tab>
            }
          </TabList>
          <TabPanel>
            <ViewCreatedPrompts refresh={promptState.submitted} />
          </TabPanel>
          <TabPanel>
            {
              isGradeAndWordLimitSelected() 
              && <PromptsForGrade grade={promptState.grade} wordLimit={promptState.wordLimit} />
            }
          </TabPanel>
        </Tabs>
      </Box>
  </div>
  );
}

export default CreatePrompt;
