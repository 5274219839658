import { Button, Grid, InputLabel } from "@mui/material";
import React, { useState } from "react";
import { FormFieldInput } from "../utils/FormFields";

interface IProps {
  changePassword: (event: any) => void;
  onChange: (event: any) => void;
  newPassword: string;
  username: string;
};

const ChangePasswordForm = (props: IProps) => {
  const {changePassword, onChange, newPassword, username} = props;
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const doPasswordsMatch = (): boolean => {
    return newPassword != confirmNewPassword;
  }

  return (
    <div className="CreateSummaryAssignmentForm">
      <br />
      <form onSubmit={(event) => {
        changePassword(event);
        setConfirmNewPassword("");
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            Changing password for user: <b>{username}</b>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormFieldInput 
              name="newPassword" 
              type="password"
              value={newPassword} 
              onChange={onChange} 
              style={{ minWidth: 400 }} 
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormFieldInput 
              name="confirmNewPassword" 
              type="password"
              value={confirmNewPassword} 
              onChange={(event) => setConfirmNewPassword(event.target.value)} 
              style={{ minWidth: 400 }} 
            />
            {
              doPasswordsMatch()
              ? <InputLabel error>Passwords don't match!</InputLabel>
              : ""
            }
          </Grid>
        </Grid>
        <Button type="submit" className="btn btn-success"
          disabled={doPasswordsMatch()}
        >
          Change Password
        </Button>
      </form>
    </div>
  )
}

export default ChangePasswordForm;

