import React from 'react';
import './App.css';
import WritingPage from './writing-page/WritingPage';
import LoginPage, { isLoggedIn } from './login-page/LoginPage';
import CreatePrompt from './create-prompt/CreatePrompt';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Container from '@mui/material/Container';
import Navbar from './navbar/Navbar';
import ViewPrompts from './view-prompts/ViewPrompts';
import AdminDashboard from './dashboards/admin-dashboard/AdminDashboard';
import CreateUser from './dashboards/student-dashboard/CreateUser';
import { createUserURL, dashboardURL, writingURL, adminURL, loginURL, viewPromptsURL, createPromptURL, performanceURL, changePasswordURL } from './constants/constants';
import DashboardSelector from './dashboards/student-dashboard/DashboardSelector';
import { getUserDetails } from './utils/GeneralUtils';
import PerformanceDashboard from './performance/PerformanceDashboard';
import { createTheme, ThemeProvider } from '@mui/material';
import { red } from '@mui/material/colors';
import { writingToolTheme } from './MUITheme';
import ChangePassword from './password-management/ChangePassword';

const componentOrLogin = (component: JSX.Element) => {
  return (
    isLoggedIn() ? (
      component
    ) : (
      <Navigate to={loginURL}/>
    )
  );
}

const loginRedirectComponent = (): JSX.Element => {
  const {is_admin} = getUserDetails();
  if (is_admin) {
    return <Navigate to={dashboardURL} />;
  } else {
    return <Navigate to={writingURL} />;
  }
}

function App() {
  return (
    <ThemeProvider theme={writingToolTheme}>
      <Router>
        <Container maxWidth="xl" className="App">
          {console.log(isLoggedIn())}
          {isLoggedIn() ? <Navbar /> : ""}
          <Routes>
            {/* <Route path='/test' element={<WordLimitDropDown />} /> */}
            <Route path={adminURL} element={<AdminDashboard />} />
            <Route path={createUserURL} element={componentOrLogin(<CreateUser />)} />
            <Route path={loginURL} element={ isLoggedIn() ? loginRedirectComponent() : <LoginPage />} />
            <Route path={viewPromptsURL} element={componentOrLogin(<ViewPrompts />)} />
            <Route path={writingURL} element={componentOrLogin(<WritingPage />)} />
            <Route path={createPromptURL} element={componentOrLogin(<CreatePrompt create={true} />)} />
            <Route path={dashboardURL} element={componentOrLogin(<DashboardSelector />)} />
            <Route path={performanceURL} element={componentOrLogin(<PerformanceDashboard />)} />
            <Route path={changePasswordURL} element={componentOrLogin(<ChangePassword />)} />
            <Route path="*" element={<Navigate to={loginURL}/>} />
          </Routes>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
