import React, { useEffect, useState } from "react";
import backendAPI, { getCreatedPromptsAPI } from "../backend-api/BackendAPI";
import { defaultPromptList } from "../constants/constants";
import { IPrompt } from "../interfaces/DataModels";
import { getAuthHeader } from "../utils/AuthUtils";
import { getUserDetails } from "../utils/GeneralUtils";
import CreatedPromptsTable from "./CreatedPromptsTable";

interface IProps {
  refresh: boolean
};

const ViewCreatedPrompts = (props: IProps) => {
  const [prompts, setPrompts] = useState<Array<IPrompt>>(defaultPromptList);

  const getCreatedPrompts = () => {
    const userDetails = getUserDetails();
    const config = {
      headers: getAuthHeader(),
    };
    backendAPI.get(getCreatedPromptsAPI, config)
    .then(response => {
      console.log("Retrieved prompts created by user: " + userDetails.username + " successfully.");
      console.log("Response data: "); console.log(response.data);
      setPrompts(response.data);
    })
    .catch(e => {
      console.log("Something went wrong."); console.log(e);
    });
  }

  useEffect(() => {
    getCreatedPrompts();
  }, [props.refresh]);

  console.log("Props: "); console.log(props);

  return (
    <div className="ViewCreatedPrompts">
      {/* {showAlert ? <Alert severity='error'>{alertContent}</Alert> : <></> } */}
      {/* <h3>Topics created by me</h3> */}
        {console.log("Prompts: ")}{console.log(prompts)}

      {
        prompts == defaultPromptList 
          ? "Loading..." 
          : prompts == [] 
            ? "No topics found." 
            : <CreatedPromptsTable rows={prompts} />
      }
      {console.log("Prompts: ")}{console.log(prompts)}
    </div>
  )
}

export default ViewCreatedPrompts;
