import backendAPI, { deletePromptAPI } from "../backend-api/BackendAPI";
import { IPrompt } from "../interfaces/DataModels";
import { getAuthHeader } from "../utils/AuthUtils";

export const deletePrompt = (prompt: IPrompt) => {
  console.log(`Attempting to delete prompt with ID: ${prompt.prompt_id}`);
  const config = {
    headers: getAuthHeader(),
    params: {
      prompt_id: prompt.prompt_id,
      school_id: prompt.school_id,
      grade: prompt.grade,
      word_limit: prompt.word_limit,
    }
  };
  backendAPI.delete(deletePromptAPI, config)
    .then(response => {
      console.log("Success: Prompt deleted.")
      console.log(response.data);
      alert('Topic deleted successfully.');
    })
    .catch(error => {
      console.log("Failure: Prompt not deleted.");
      console.log(error.response);
      let errorMessage = "Could not delete Topic. "
      try {
        errorMessage += error.response.data.detail;
      } catch (dummy) {}
      alert(errorMessage);
    });
}
