import backendAPI, { getPromptsByGradeAPI, makeSubmissionAPI } from "../backend-api/BackendAPI";
import { getAuthHeader } from "../utils/AuthUtils";
import { getUserDetails } from "../utils/GeneralUtils";


export const getPrompts = (wordLimit: number, promptData: any, setPromptData: any) => {
  const userDetails = getUserDetails();
  const config = {
    headers: getAuthHeader(),
    params: {
      grade: userDetails.grade,
      word_limit: wordLimit
    }
  };
  backendAPI.get(getPromptsByGradeAPI, config)
    .then(response => {
      console.log("Retrieved prompts for grade: " + userDetails.grade + " successfully.");
      console.log("Response data: "); console.log(response.data);
      setPromptData({
        ...promptData,
        prompts: response.data
      });
    })
    .catch(e => {
      console.log("Something went wrong."); console.log(e);
    });
}

export const makeSubmission = (
  data: any,
  config: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setFeedbackURL: React.Dispatch<React.SetStateAction<string>>,
  setShowFeedback: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  backendAPI.post(makeSubmissionAPI, data, config)
    .then(response => {
        console.log(response.data);
        const grammarFeedbackURL = response.data;
        console.log(grammarFeedbackURL);
        setIsLoading(false);
        setFeedbackURL(grammarFeedbackURL);
        console.log('Data sent to backend successfully.');
        setShowFeedback(true);
    })
    .catch(error => {
        console.log("Failed: Could not get feedback.");
        console.log(error);
        alert('Failed to get feedback.');
        setShowFeedback(false);
        setIsLoading(false);
    })
    .finally(() => {
      console.log("Finally hit.");
      setIsLoading(false);
    });
}
