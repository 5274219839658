import axios from 'axios';
import { userDetailsKey } from '../constants/constants';
import { IUserDetails, defaultUserDetails } from '../interfaces/FrontendInterfaces';

export const convertTimeValueToDate = (timeValue: string): Date => {
  const date = new Date(Number(timeValue));
  console.log("Date: "); console.log(date);
  return date;
}

export const findNumberOfWords = (text: string): number => {
  // console.log("text: `" + text + '`');
  if (text.trim() === '') {
    return 0;
  }
  // console.log(Array(text.split(' ')));
  const numWords: number = text.split(' ').filter(word => word.length > 0).length;
  return numWords;
}

export const getUserDetails = (): IUserDetails => {
  // Returns the UserDetails object for the authenticated user, or defaultUserDetails if no user is logged in.
  const userDetailsString = localStorage.getItem(userDetailsKey);
  if (userDetailsString) {
    const userDetails: IUserDetails = JSON.parse(userDetailsString);
    console.log("After parsing, user details are: "); console.log(userDetails);
    return userDetails;
  } else {
    return defaultUserDetails;
  }
}

export const preventPasting = (event) => {
  event.preventDefault()
  console.log("Pasting is not allowed.")
  alert("Pasting is not allowed.")
  return false;
}

export const fetchTextFromS3 = (
  textURL: string,
  setText: React.Dispatch<React.SetStateAction<string>>
) => {
  axios.get(textURL)
    .then(response => {
      console.log(`Retrieved text from URL: ${textURL} successfully.`)
      console.log(`Submission text to pre-load: ${response.data.slice(0, 30)}...`);
      setText(response.data);
    })
    .catch(error => {
      console.log(`Failed to retrieve text from URL: ${textURL}.`);
    })
}

export const isValidHttpUrl = (urlString: string): boolean => {
  let url: URL;
  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export const mapIsSummaryToAssignmentType = (isSummary: boolean): string => {
  return isSummary ? "Summary" : "Essay";
}
