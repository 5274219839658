import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import backendAPI, { changePasswordAPI } from "../backend-api/BackendAPI";
import { getAuthHeader } from "../utils/AuthUtils";
import { getUserDetails } from "../utils/GeneralUtils";
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = () => {
  const location = useLocation();
  const locationState = location.state;
  let username: string | null = null;
  if (locationState) {
    // @ts-ignore
    username = locationState.username;
  }
  if (!username) {
    const userDetails = getUserDetails();
    username = userDetails.username;
  }
  console.log(`Username: ${username}`);

  const [newPassword, setNewPassword] = useState("");

  const changePassword = (event) => {
    event.preventDefault();
    const data = {
      username: username,
      password: newPassword,
    };
    const config = {
      headers: getAuthHeader()
    };
    console.log("Attempting to change password.");
    backendAPI.post(changePasswordAPI, data, config)
      .then(response => {
        console.log("Success: Password changed successfully.")
        setNewPassword("");
        console.log(response.data);
        alert(`Password changed successfully.`);
      })
      .catch(error => {
        console.log("Failure: Could not change password.");
        console.log(error.response);
        const errorMessage = "Could not change password. "
        try {
          errorMessage.concat(error.response.data.detail);
        } catch (dummy) {}
        alert(errorMessage);
      });
  }

  const onChange = (event) => {
    const {value} = event.target;
    setNewPassword(value);
  }

  return (
    <ChangePasswordForm
      changePassword={changePassword}
      newPassword={newPassword}
      onChange={onChange}
      username={username}
    />
  )
}

export default ChangePassword;
