import React from 'react';
import { Checkbox, Input, InputLabel, MenuItem, Select, TextField } from "@mui/material";

const capitalizeWord = (word: string) => {
  const [first, ...rest] = word;
  return first.toLocaleUpperCase() + rest.join('');
}

const capitalizeIdentifier = (identifier: string) => {
  const words = identifier.split('_');
  const pascalCasedIdentifier = words.map(word => capitalizeWord(word)).join('');
  // Converted snake cased identifiers to Pascal case. Now to separate the words.
  return pascalCasedIdentifier.split(/(?=[A-Z])/).join(' ');
}

export const FormFieldInput = (props: any) => {
  const { name, value, type, onChange, ...rest } = props;
  return (
    <div className="form-group" style={{ display: 'inline-block', marginLeft: '20px' }}>
      <InputLabel htmlFor={name}>{capitalizeIdentifier(name)}</InputLabel>
      <TextField
        type={type}
        className="form-control"
        id={name}
        required
        name={name}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
}

FormFieldInput.defaultProps = {
  type: "text"
};

export const FormFieldDropDown = (props: any) => {
  const noOptions: boolean = (!props.items || props.items.length == 0);
  console.log(`No options in drop down for ${props.name}? ${noOptions}`);
  const { name, value, onChange, items, ...rest } = props;
  return (
    <div className="form-group" style={{ display: 'inline-block', marginLeft: '20px' }}>
      <InputLabel htmlFor={props.name}>{capitalizeIdentifier(props.name)}</InputLabel>
      <Select 
        value={value}
        name={name}
        id={name}
        onChange={onChange}
        required
        sx={{ minWidth: 80 }}
        disabled={noOptions}
        {...rest}
      >
      { noOptions && <MenuItem value=''><em>None</em></MenuItem> }
      {items
        .map(item => (
          <MenuItem key={item} value={item}>
            {props.multiple &&
              <Checkbox checked={value.indexOf(item) > -1} />}
            {item.toString()}
          </MenuItem>
      ))}
      </Select>
    </div>
  );
}

export const FormFieldTextBox = (props: any) => {
  const { name, value, onChange, ...rest } = props;
  return (
    <div className="form-group" style={{ marginLeft: '20px' }}>
      <InputLabel htmlFor={props.name}>{capitalizeIdentifier(props.name)}</InputLabel>
      <TextField 
        className="form-control"
        id={name}
        name={name}
        value={value}
        required
        onChange={onChange}
        multiline 
        fullWidth
        margin="normal"
        minRows={5}
        {...rest}
      />
    </div>
  );
}

FormFieldTextBox.defaultProps = {
  helperText: "Add a description for the topic"
};
