import { Button, FormControl, Input, InputLabel } from '@mui/material';
import React, { useState } from "react";
import backendAPI, { loginAPI } from '../backend-api/BackendAPI';
import { authTokenKey, dashboardURL, loginURL, userDetailsKey, writingURL } from '../constants/constants';
import { FormFieldInput } from '../utils/FormFields';
import { useNavigate } from 'react-router-dom';

export function isLoggedIn() {
  return localStorage.getItem(authTokenKey) != null;
}

export const logOutUser = (): boolean => {
  const wasLoggedIn = isLoggedIn();
  localStorage.removeItem(authTokenKey);
  window.location.href = loginURL;
  return wasLoggedIn;
}

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleUsername = (event) => {
    setUsername(event.target.value);
  }
  const handlePassword = (event) => {
    setPassword(event.target.value)
  }
  const loginUser = (loginDetails) => {
    const loginFormData = new FormData();
    loginFormData.append('username', loginDetails['username']);
    loginFormData.append('password', loginDetails['password']);
    console.log("Login form data: "); console.log(loginFormData);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    backendAPI.post(loginAPI, loginFormData, config)
      .then(response => {
        console.log("Response: "); console.log(response);
        const auth_token = response.data.access_token;
        console.log(auth_token);
        localStorage.setItem(authTokenKey, auth_token);

        const userDetails = response.data.user_details;
        console.log("User details: "); console.log(userDetails);
        const userDetailsString = JSON.stringify(userDetails);
        localStorage.setItem(userDetailsKey, userDetailsString);

        // this.props.history.push('/dashboard');
        const {is_admin} = userDetails;
        if (is_admin) {
          navigate(dashboardURL);
        } else {
          navigate(writingURL);
        }
        // window.location.href = dashboardURL;
        window.location.reload();
      })
      .catch(error => {
        console.log("Error encountered: ");
        console.log(error);
        alert('Invalid details provided.');
        // document.getElementById("username")?.textContent = "";
      })
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('A name was submitted: ' + username + " with password: " + password);
    const loginDetails = {
      username: username,
      password: password
    };
    loginUser(loginDetails);
  }

  return (
    <div className="Login">
      <br /> <br />
      <h1>Writing Tool for Education</h1>
      <br /> 
      <form onSubmit={handleSubmit}>
        <FormFieldInput name="username" value={username} onChange={handleUsername}/>
        <FormFieldInput name="password" value={password} onChange={handlePassword} type="password"/>

        <br /> <br />
        <Button type="submit" color="primary">Login</Button>
      </form>
    </div>
  );
}

export default LoginPage;
