import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IPrompt } from "../interfaces/DataModels";
import { fetchTextFromS3, isValidHttpUrl, mapIsSummaryToAssignmentType } from '../utils/GeneralUtils';

interface IProps {
  isAPromptSelected: () => boolean;
  promptData: {
    prompt: IPrompt;
    prompt_id: string;
    prompts: IPrompt[];
  };
  getWordLimitLowerBound: () => number;
  wordLimit: number;
};

const InstructionText = (props: IProps) => {
  const {isAPromptSelected, promptData, getWordLimitLowerBound, wordLimit} = props;
  const isSummary = promptData.prompt.is_summary;
  const instructionMessage = isSummary
    ? `Summarize the article provided on the chosen topic`
    : `Write an essay on the chosen topic`;
  const promptDescription = promptData.prompt.description;
  console.log(`Description: ${promptDescription.slice(0, 30)}...`);
  const [instructionDescription, setInstructionDescription] = useState<string>(promptDescription);
  useEffect(() => {
    if (isSummary) {
      if (isValidHttpUrl(promptDescription)) {
        fetchTextFromS3(promptDescription, setInstructionDescription);
      } else {
        console.log("promptDescription is not a valid URL.");
        setInstructionDescription(promptDescription);
      }
    } else {
      setInstructionDescription(promptDescription);
    }
  }, [promptDescription]);

  return (
    <div className="InstructionText">
      {
        isAPromptSelected() && promptData.prompt &&
        <div>
          <h3 className="left-aligned-header">Assignment Type: {mapIsSummaryToAssignmentType(promptData.prompt.is_summary)}</h3>
          <h3 className="left-aligned-header">{instructionMessage}, in {getWordLimitLowerBound()} to {wordLimit} words.</h3>
        </div>
      }
      {
        isAPromptSelected() && promptData.prompt &&
        <div>
          <h3>Topic: {promptData.prompt.heading}</h3>
          <h4 style={{'whiteSpace': 'pre-line'}}>{instructionDescription}</h4>
        </div>
      }
    </div>
  )
}

export default InstructionText;
